import { AdfsCreds } from "../utils/SecretsManager";
import { endpointURL } from "src/constants/EndpointStageMapping";

const apiEndpoint: string = endpointURL["prod"];

export async function fetchAdfsCredentials() : Promise<AdfsCreds>{

    try {
        let adfsConfig = await fetch(`${apiEndpoint}/auth/config`,
        {
           method: 'GET',
           headers: {'Content-Type': 'application/json'}
        });
        const response = await adfsConfig.json();
        return response as AdfsCreds;
    } catch (error) {
        console.log('Error while fetching adfs client config with message:' + error);
        return {};
    }
}

export async function fetchAdfsToken(grantType: string, code: string) {

    const payload = {
        'grantType': grantType,
        'code': code
    };
    try {
        let adfsConfig = await fetch(`${apiEndpoint}/auth/token`,
        {
           method: 'POST',
           headers: {'Content-Type': 'application/json'},
           body: JSON.stringify(payload)
        });
        const response = await adfsConfig.json();
        return response;
    } catch (error) {
        console.log('Error while fetching access token from adfs token api :' + error);
        return {};
    }
}
