// Mirrored in:
// - src/styles/katal-theme/_variables.scss
// - src/test/nightwatch-helper.js
export const MOBILE_ENHANCEMENT_INCLUDE_CLASSNAME =
  'katal-mobile-enhancements-include';

// Mocking exclusively for Jest environments of our consumers
// Otherwise, if their tests use Popover, they will fail.
// https://stackoverflow.com/questions/39830580/jest-test-fails-typeerror-window-matchmedia-is-not-a-function
export const safeMatchMedia =
  window?.matchMedia ??
  function () {
    return {
      matches: false,
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };

export const mobileMediaQuery = safeMatchMedia('(max-width: 720px)');

export const hasMobileIncludeClass = () =>
  document.body?.classList.contains(MOBILE_ENHANCEMENT_INCLUDE_CLASSNAME);

export const isMobile = () =>
  mobileMediaQuery?.matches && hasMobileIncludeClass();

// iOS does not treat the software keyboard as modifying the viewHeight for 'vh' units.
// Calculate it here with the "visualViewport" so we can recalculate modal heights
// when the keyboard is present.
// Ref: https://css-tricks.com/newsletter/232-scroll-story/
let KATAL_IS_LISTENING_FOR_VISUAL_VIEWPORT_RESIZE = false;

const visualViewportResizeListener = () => {
  const vh = window.visualViewport.height * 0.01;
  document.documentElement.style.setProperty('--kat-vh', `${vh}px`);
};

export const addVisualViewportResizeListener = () => {
  if (KATAL_IS_LISTENING_FOR_VISUAL_VIEWPORT_RESIZE) {
    return;
  }

  window.visualViewport?.addEventListener(
    'resize',
    visualViewportResizeListener
  );
  KATAL_IS_LISTENING_FOR_VISUAL_VIEWPORT_RESIZE = true;
};
