
export const TEST = "test";
export const BETA = "beta";
export const GAMMA = "gamma";
export const PROD = "prod";

export const endpointURL: any = {
    [TEST]: 'https://n1op4kv31f.execute-api.us-east-1.amazonaws.com/Beta/zapposMarketingDashboardService',
    [BETA]: 'https://n1op4kv31f.execute-api.us-east-1.amazonaws.com/Beta/zapposMarketingDashboardService',
    [GAMMA]: 'https://7e9d7hvj00.execute-api.us-east-1.amazonaws.com/Gamma/zapposMarketingDashboardService',
    [PROD]: 'https://ew3lhnc2j8.execute-api.us-east-1.amazonaws.com/Prod/zapposMarketingDashboardService'
};

//todo: Will be removed later once ZFT in BETA gets fixed.
export const endPointURLForPresignedURLAPI: any = {
    [TEST]: 'https://7e9d7hvj00.execute-api.us-east-1.amazonaws.com/Gamma/zapposMarketingDashboardService',
    [BETA]: 'https://7e9d7hvj00.execute-api.us-east-1.amazonaws.com/Gamma/zapposMarketingDashboardService',
    [GAMMA]: 'https://7e9d7hvj00.execute-api.us-east-1.amazonaws.com/Gamma/zapposMarketingDashboardService',
    [PROD]: 'https://ew3lhnc2j8.execute-api.us-east-1.amazonaws.com/Prod/zapposMarketingDashboardService'
}
